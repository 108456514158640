/* eslint-disable @typescript-eslint/no-unused-vars */

import { DispatchEventAction, IUser } from '@nab/x-spa-react';
import { ITenantEvents } from 'tenants/_strategy/lib/types';
import { handleAuthentication } from 'lib/sessionHelpers';
import { configMgr } from '../../../lib/configMgr';
import { Tenant } from '../Tenant';

export class TenantEvents implements ITenantEvents {
  public handleIdentityMiniappEventLoaded(
    event: any,
    dispatchEvent: DispatchEventAction,
    type: string,
    content?: string
  ) {
    if (content) {
      dispatchEvent(this.ref.identity.DISPLAY_ERROR_MESSAGE, { type, content });
    }
  }

  public async handleIdentityMiniappEventAuthenticated(event: any, user?: IUser): Promise<void> {
    await handleAuthentication(event);
  }

  public handleLogoutButton(logoutUser: Function): void {
    const redirectUrlAfterLogout = configMgr.get('LOGOUT_REDIRECT_URL');
    const emitAnalyticsOnLogout = true;
    if (Tenant.instance().effects.confirmLogout()) {
      logoutUser(redirectUrlAfterLogout, emitAnalyticsOnLogout);
    }
  }

  public handleEventRoute?(event: any, eventName: string): void {
    throw new Error('Method not implemented.');
  }

  public get ref() {
    return {
      /**
       * DAF Identity Miniapp
       */
      identity: {
        USER_AUTHENTICATED: 'user_authenticated',
        MINIAPP_LOADED_IDENTITY: 'DA_IDENTITY:app_loaded',
        DISPLAY_ERROR_MESSAGE: 'display_message'
      }
    };
  }
}
