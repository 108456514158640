/* eslint-disable max-statements */
import Cookies from 'js-cookie';

import { configMgr } from 'lib/configMgr';
import { httpClient } from 'lib/httpClient';
import { constants } from 'tenants/constants';
import { TenantSession } from 'tenants/_strategy';

import { getMiniappRolloutFlagsFromLocalStorage } from '../nav-manager/navManagerHelper';

export class Session extends TenantSession {
  public override async revoke(csid: string, currentToken?: string): Promise<void> {
    const token = currentToken || Cookies.get(constants.ib.SESSION_COOKIE);

    if (token) {
      const requestUrl = configMgr.get('KONG_DOMAIN') + configMgr.get('REVOKE_TOKEN_PATH');

      await httpClient.post(
        requestUrl,
        {
          client_id: configMgr.get('AUTH_CLIENT_ID'),
          token,
          token_type_hint: constants.ib.TOKEN_TYPE_HINT
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-nab-clsid': csid
          },
          'axios-retry': {
            retries: 2,
            retryCondition: error => error?.response?.status !== 200
          }
        }
      );
    }
  }

  public override async clear(): Promise<void> {
    // Clearing session for Authentication
    sessionStorage.removeItem(constants.ib.IS_FIRST_LOGIN);
    sessionStorage.removeItem(constants.ib.DAF_SSC_HASH);
    sessionStorage.removeItem(constants.ib.CUSTOMER_SESSION_ID);

    //clearSessionToken
    Cookies.remove(constants.ib.SESSION_COOKIE);
    Cookies.remove(constants.ib.SESSION_TIMESTAMP);
    Cookies.remove(constants.ib.CAN_SWITCH);
    Cookies.remove(constants.ib.DAF_SSC_HASH);
    Cookies.remove(constants.ib.COOKIE_IS_AUTHENTICATED);
    sessionStorage.removeItem(constants.ib.IS_USER_LOGGED_IN);

    //clearUserDataIdentityMiniapp
    sessionStorage.removeItem(constants.common.BEARER_TOKEN);
    sessionStorage.removeItem(constants.ib.IDENTITY_INFO);

    // Clear Notification Session Storage Key for Remind me later
    sessionStorage.removeItem(constants.ib.NOTIFICATION_SESSION_STORAGE_CONSTANT);

    // clear cached notifications
    sessionStorage.removeItem(constants.ib.CACHED_ALL_IB_NOTIFICATIONS);
    sessionStorage.removeItem(constants.ib.CACHED_INVITATION_ACCOUNT);
    sessionStorage.removeItem(constants.ib.CACHED_ACCOUNT_SUMMARY);
    sessionStorage.removeItem(constants.ib.NOTIFICATION_PAGE_VISITED);

    //cleanUpMiniappRolloutFlags
    const featureFlagsStorage = getMiniappRolloutFlagsFromLocalStorage();
    if (featureFlagsStorage?.length) {
      featureFlagsStorage.forEach(item => {
        Cookies.remove(item);
      });
    }

    localStorage.removeItem(constants.ib.LOCAL_STORAGE_ROLLOUT_FLAGS);
    return Promise.resolve();
  }

  public override token(): string {
    const token: string | null = Cookies.get(constants.ib.SESSION_COOKIE);

    return typeof token === 'string' ? `Bearer ${token}` : '';
  }

  public override isValid(): boolean {
    const sessionId = Cookies.get(constants.ib.DAF_SSC_HASH);
    const sessionCookie = Cookies.get(constants.ib.SESSION_COOKIE);
    const isAuthenticated = Cookies.get(constants.ib.COOKIE_IS_AUTHENTICATED);
    const ssc = typeof sessionId === 'string';

    return isAuthenticated === 'true' && ssc && typeof sessionCookie === 'string';
  }

  public override userId(): string {
    const sessionId = Cookies.get(constants.ib.DAF_SSC_HASH);

    const ssc = typeof sessionId === 'string';

    return ssc ? sessionId : '';
  }
}
