import { Container } from '@nab/nui-react';
import { SpaProps, useActions } from '@nab/x-spa-react';
import GlobalHeader from 'components/GlobalHeader/GlobalHeader';
import { GenericError, Viewport } from 'components/common';
import { useLogoutCleanup } from 'hooks/useLogout';
import { configMgr } from 'lib/configMgr';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tenant } from 'tenants/_strategy';
import { ArStrategy } from 'tenants/adviser-registration';
import { ERROR_CODE } from 'tenants/nab-portal-pay/types';
import { StyledContentContainer, StyledMainWrapper } from './AdviserRegistrationSpa.style';

export const AdviserRegistrationSpa: React.FC<SpaProps> = ({ activeAppInstance, context, initError }) => {
  const tenant = Tenant.instance() as ArStrategy;

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitError, setIsInitError] = useState(false);

  const mainRef = useRef(null);

  const { addEventListener } = useActions();

  const { logoutUser } = useLogoutCleanup();

  useEffect(() => {
    const sessionIsValid = tenant.session.isValid();
    setIsAuthenticated(sessionIsValid);

    if (!sessionIsValid) {
      logoutUser(`/login?error=${ERROR_CODE.UNAUTHORISED}`);
    }
  }, []);

  useEffect(() => {
    if (initError) {
      setIsInitError(true);
    }
  }, [initError]);

  useEffect(() => {
    const changePassowordMiniappEvents = Object.values(tenant.events.ref.changePassword).map(eventName => {
      return addEventListener(eventName, event => tenant.events.handleEventRoute(event, eventName));
    });

    return () => {
      changePassowordMiniappEvents.forEach(unsubscribe => unsubscribe());
    };
  }, [addEventListener, dispatchEvent, logoutUser]);

  const menu = useMemo(() => context?.menu || [], [context?.menu]);
  const bgImage = useMemo(() => (isInitError ? null : configMgr.get('LOGIN_BACKGROUND_IMAGE_URL')), [isInitError]);
  const viewport = useMemo(() => {
    if (isInitError) {
      return <GenericError />;
    }

    const isHomeItemHidden =
      activeAppInstance?.route?.ownPattern === '/home' && activeAppInstance?.appConfig?.shellConfig?.hidden === true;

    return (
      <Container>
        <StyledContentContainer>
          {!isHomeItemHidden && (
            <Viewport activeAppInstance={activeAppInstance} errorSlotOverride={() => <GenericError />} />
          )}
        </StyledContentContainer>
      </Container>
    );
  }, [activeAppInstance, isInitError]);

  return (
    <>
      <GlobalHeader
        activeAppInstance={activeAppInstance}
        mainRef={mainRef}
        navMgrMenu={menu}
        isAuthenticated={isAuthenticated}
      />
      <StyledMainWrapper imageUrl={bgImage} ref={mainRef} tabIndex={-1} data-testid="adviser-registration-spa">
        {viewport}
      </StyledMainWrapper>
    </>
  );
};
