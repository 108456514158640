import styled from 'styled-components';
import { media } from '@nab/nui-react';
import { rem } from 'polished';

export const StyledIframeContainer = styled.div<{ isEmpty: boolean }>`
  position: relative;
  height: ${({ isEmpty }) => (isEmpty ? 0 : 'auto')};
  visibility: ${({ isEmpty }) => (isEmpty ? 'hidden' : 'visible')};
  overflow: hidden;
  padding: ${({ isEmpty, theme }) => (isEmpty ? 0 : `${theme.spaces.lg} ${rem(0)}`)};
  ${media.lg`
    padding: ${({ isEmpty, theme }) => (isEmpty ? 0 : `${theme.spaces.xlg} ${rem(28)}`)};
  `}
`;

export const StyledIframeInnerWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.sm};
  background-color: ${({ theme }) => theme.color.white};
`;
