import {
  AuthStrategy,
  AuthToken,
  DafAuthConfig,
  DafSeedAuthConfig,
  DafAuthToken,
  getShellActions,
  DafTokenExchangeError
} from '@nab/x-spa-react';
import Cookie from 'js-cookie';
import { constants } from 'tenants/constants';
import { EVENTS } from 'tenants/types';

const getSeedToken = async (): Promise<string | DafAuthToken> => {
  return new Promise((resolve, reject) => {
    const token = Cookie.get(constants.common.USL_SESSION_COOKIE_ID);
    if (typeof token === 'string') {
      resolve(token);
    } else {
      // If a token doesn't exist, kick off the authentication chain by hooking into it's overloaded axios.
      // The below event handler will trigger if things are successful.
      //logout(this.window);
      reject();
    }
  });
};

export class CookieAuthStrategy implements AuthStrategy<DafAuthConfig> {
  constructor(protected supportedAuthConfig?: DafAuthConfig) {}

  /**
   * The config for the token being requested must match that which is supported by your seedTokenProvider
   */
  public supports(config: DafAuthConfig): boolean {
    return (
      config.tokenSource === 'DAF' &&
      (config.isSeed === true || ['root-spa', 'SHELL'].includes(config?.appMetaData?.id))
    );
  }

  public async authenticate(config: DafSeedAuthConfig): Promise<AuthToken<DafSeedAuthConfig>> {
    if (config.isInvalidSeedToken) {
      getShellActions().dispatchEvent(EVENTS.GLOBAL_ERROR_EVENT_NAME, {
        code: constants.globalAuthErrorCodes.INVALID_OR_MISSING_TOKEN_401
      });
      throw new Error(DafTokenExchangeError.INVALID_SEED_TOKEN);
    }

    const token: string | DafAuthToken = await getSeedToken();

    const sixtyMinutesExpiry: number = 60 * 60 * 1000; // 60 minutes * 60 seconds * 1000 milliseconds

    if (typeof token === 'string') {
      return {
        id: constants.common.USL_SESSION_COOKIE_ID,
        tokenValue: token,
        expires: Date.now() + sixtyMinutesExpiry,
        config
      };
    }

    if (token && typeof token === 'object') {
      return {
        ...token,
        config
      };
    }

    throw new Error(`Seed token provider did not return a token`);
  }
}
