import React, { useCallback, useState } from 'react';
import { useActions } from '@nab/nab-x-react';
import { Form, FormInput, FormItem } from '@nab/nui-react';

import { Tenant } from 'tenants/_strategy';
import { constants } from 'tenants/constants';

import NabPortalPayIdentityStub from 'components/LoginSpa/stubs/NabPortalPayIdentityStub';

import { FormWrapper, StyledIdentityStubHeading, StyledIdentityStubLoginButton } from './IdentityStub.style';

const IdentityStub: React.FC = () => {
  const { dispatchEvent } = useActions();
  const [username, setUsername] = useState<string>('');

  const submitHandler = useCallback(
    (param: { username: string }) => {
      return dispatchEvent(Tenant.instance().events.ref.identity.USER_AUTHENTICATED, {
        [constants.common.BEARER_TOKEN]: param.username,
        identityInfo: {
          username: param.username
        },
        canSwitch: false,
        canLink: true
      });
    },
    [dispatchEvent]
  );

  if (Tenant.instance().name === constants.common.NAB_PORTAL_PAY_TENANT) {
    return <NabPortalPayIdentityStub />;
  }

  return (
    <FormWrapper>
      <Form
        initialValues={undefined}
        method="post"
        onSubmit={props => {
          if (props.isValid) {
            submitHandler({ username });
          }
        }}
      >
        <StyledIdentityStubHeading variant="impact">Internet Banking</StyledIdentityStubHeading>

        <FormItem
          name="username"
          label={'NAB ID'}
          value={username}
          required
          data-testid="username"
          onChange={event => {
            setUsername(event.target.value);
          }}
          validations={[{ name: 'required', message: constants.common.STUB_VALIDATION_MESSAGE }]}
        >
          {props => <FormInput {...props} autoComplete="off" />}
        </FormItem>
        <StyledIdentityStubLoginButton type="submit" variant="primary" label={constants.common.LOGIN_BUTTON_LABEL} />
      </Form>
    </FormWrapper>
  );
};

export default IdentityStub;
