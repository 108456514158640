import { useActions } from '@nab/x-spa-react';

import { MutableRefObject, useCallback } from 'react';
import { Tenant } from 'tenants/_strategy';
import { WIDGET_NAME } from 'tenants/types';

type EmbedWidgetAppArgs = {
  widgetName?: WIDGET_NAME;
  ref?: MutableRefObject<HTMLDivElement>;
};

export const useWidget = () => {
  const { embedMiniApp } = useActions();

  const embedWidgetApp = useCallback(
    async ({ widgetName, ref }: EmbedWidgetAppArgs): Promise<void> => {
      const widgetAppAsset = await Tenant.instance().core.getContext(widgetName);

      await embedMiniApp({
        ...widgetAppAsset,
        hostElement: ref.current
      });
    },
    [embedMiniApp]
  );

  return { embedWidgetApp };
};
