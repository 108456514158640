import React, { useState } from 'react';
import { CloseIcon, SettingsIcon, ButtonIcon } from '@nab/nui-react';

import { LeftPanel, LeftPanelButtons, LeftPanelScrollWrapper } from './LeftPanel.style';
import SidePanel from './SidePanel';

export interface MasterScaffoldProps {
  children: React.ReactNode;
  sidePanel: React.ReactNode;
}

export interface LeftMenuProps {
  sidePanel: React.ReactNode;
}

const LeftMenu: React.FC = () => {
  const [leftPanelIsClosed, setLeftPanelIsClosed] = useState(true);
  return (
    <LeftPanel className={leftPanelIsClosed && 'isClosed'}>
      <LeftPanelButtons>
        <ButtonIcon
          isOnDarkBg
          icon={leftPanelIsClosed ? SettingsIcon : CloseIcon}
          onClick={() => {
            setLeftPanelIsClosed(!leftPanelIsClosed);
          }}
          label={''}
          color={'primary'}
        />
      </LeftPanelButtons>
      <LeftPanelScrollWrapper>
        <SidePanel />
      </LeftPanelScrollWrapper>
    </LeftPanel>
  );
};
export default LeftMenu;
