import { useActions } from '@nab/x-spa-react';

import { useEffect } from 'react';
import { Tenant } from 'tenants/_strategy';

export const useDisplayError = () => {
  const { dispatchEvent, addEventListener } = useActions();
  const params = new URLSearchParams(window.location.search);
  const code = params.get('error');
  const message = code ? Tenant.instance().helpers.getErrorMessage(code) : '';

  useEffect(() => {
    const unsubscribe = addEventListener(Tenant.instance().events.ref.identity.MINIAPP_LOADED_IDENTITY, event =>
      Tenant.instance().events.handleIdentityMiniappEventLoaded(event, dispatchEvent, 'error', message)
    );

    return () => unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
