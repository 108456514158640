import { TenantStrategy } from './TenantStrategy';

export class Tenant {
  private _strategy: TenantStrategy;
  private static _instance: Tenant | undefined;

  private constructor(strategy: TenantStrategy) {
    this._strategy = strategy;
  }

  public static instance(strategy?: TenantStrategy): TenantStrategy {
    if (Tenant._instance) {
      return Tenant._instance._strategy;
    }

    if (strategy) {
      Tenant._instance = new Tenant(strategy);
      return Tenant._instance._strategy;
    }

    throw new Error(`Failed init Tenant! No strategy provided!`);
  }

  /**
   * To be only used in unit tests
   */
  public static clearInstance(): void {
    if (Tenant._instance) {
      Tenant._instance = undefined;
    }
  }
}
