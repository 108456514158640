import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';

interface AxiosRetryRequestConfig extends AxiosRequestConfig {
  'axios-retry'?: {
    retries: number;
    retryCondition?: (error: any) => boolean;
  };
}

interface HttpClient extends AxiosInstance {
  (config: AxiosRetryRequestConfig): AxiosPromise;
  (url: string, config?: AxiosRetryRequestConfig): AxiosPromise;
}

export const httpClient: HttpClient = axios.create();

axiosRetry(httpClient, { retries: 0 });
