interface ReadingTimeOptions {
  wordsPerMinute?: number; // The average number of words a person can read per minute
  minDuration?: number; // The minimum duration in milliseconds for the estimated reading time
  maxDuration?: number; // The maximum duration in milliseconds for the estimated reading time
}

/**
 * Estimates the time it takes to read a given text aloud.
 * @param text - The text to estimate reading time for.
 * @param options - Optional parameters to adjust the estimation.
 * @returns Estimated reading time in milliseconds.
 */
export const estimateTextReadingTime = (
  text: string,
  { wordsPerMinute = 150, minDuration = 1500, maxDuration = 8000 }: ReadingTimeOptions = {}
): number => {
  // If the text is empty, return a default duration of 5 seconds
  if (!text) return 5000;

  // Split the text into words and add the buffer length to the word count
  const words = text.trim().split(/\s+/);
  const wordCount = words.length;

  // Calculate the duration based on the word count and the average reading speed
  const msPerWord = 60000 / wordsPerMinute;
  let duration = wordCount * msPerWord;

  // Adjust the duration based on the text length
  // Shorter texts (3 words or fewer) are multiplied by 1.2 to account for overhead
  // Longer texts (more than 10 words) are multiplied by 0.9 to account for reading efficiency
  duration *= wordCount <= 3 ? 1.2 : wordCount > 10 ? 0.9 : 1;

  // Adjust the duration for complex words (words with more than 8 characters)
  // Add 200 milliseconds for each complex word
  const complexWordCount = words.filter(word => word.length > 8).length;
  duration += complexWordCount * 200;

  // Adjust the duration for all-caps text (more than 10 characters)
  // Multiply the duration by 1.1 to account for the increased difficulty in reading
  if (text === text.toUpperCase() && text.length > 10) {
    duration *= 1.1;
  }

  // Ensure the duration falls within the specified minimum and maximum durations
  return Math.max(minDuration, Math.min(maxDuration, Math.round(duration)));
};
