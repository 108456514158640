import React from 'react';
import { Table, THead, TBody, TRow, Th, Td } from '@nab/nui-react';

export interface EventData {
  timestamp: string;
  name: string;
  data: any;
}
export interface EventLogProps {
  events: EventData[];
}

function replacerFn(key, value) {
  if (value instanceof HTMLElement) {
    return undefined;
  }

  return value;
}

const EventRow: React.FC<Partial<EventData>> = ({ name, data }) => {
  return (
    <TRow onClick={() => {}}>
      <Td header="Event" headers="event">
        {name}
      </Td>
      <Td header="Data" headers="data">
        <pre>{data && JSON.stringify(data, replacerFn, 2)}</pre>
      </Td>
    </TRow>
  );
};

const EventLog: React.FC<EventLogProps> = ({ events }) => {
  return (
    <Table>
      <THead>
        <TRow onClick={() => {}}>
          <Th id="event"> Event </Th>
          <Th id="data"> Data </Th>
        </TRow>
      </THead>
      <TBody>
        {events.map(({ timestamp, name, ...rest }) => (
          <EventRow key={`${timestamp}_${name}`} name={name} {...rest} />
        ))}
      </TBody>
    </Table>
  );
};

EventLog.defaultProps = {
  events: []
};

export default EventLog;
