import { DafAuthConfig, DafGrantType } from '@nab/x-spa-react';

type AuthConfigParams = {
  environment: string;
  clientId: string;
  scopes: string;
};

export enum ShellAuthConfigType {
  Anonymous = 'Anonymous',
  Authenticated = 'Authenticated'
}

export class AuthConfigs {
  private static instance: AuthConfigs | undefined;

  /**
   * Pre-defining the Pre login config
   */
  private _preLoginAuthConfig: DafAuthConfig = {
    tokenType: 'Bearer',
    tokenSource: 'DAF',
    context: 'external',
    grantType: DafGrantType.Anonymous,
    requestTokenOnLoad: false,
    isSeed: false
  };

  /**
   * Pre-defining Post Login config
   */
  private _postLoginAuthConfig: DafAuthConfig = {
    tokenType: 'Bearer',
    tokenSource: 'DAF',
    context: 'external',
    grantType: null,
    isSeed: false
  };

  private _clientId: string;
  private _environment: string;
  private _scopes: string;

  static getInstance(params?: AuthConfigParams): AuthConfigs {
    if (AuthConfigs.instance) {
      return AuthConfigs.instance;
    }

    if (params) {
      AuthConfigs.instance = new AuthConfigs(params);
      return AuthConfigs.instance;
    } else {
      throw new Error(`Cannot init AuthConfig, lack of input parmas`);
    }
  }

  constructor({ environment, clientId, scopes }: AuthConfigParams) {
    this._preLoginAuthConfig = {
      ...this._preLoginAuthConfig,
      environment: environment,
      clientId: clientId,
      scopes
    };

    this._postLoginAuthConfig = {
      ...this._postLoginAuthConfig,
      environment: environment
    };

    this._clientId = clientId;
    this._environment = environment;
    this._scopes = scopes;
  }

  public get postLoginAuthConfig() {
    return this._postLoginAuthConfig;
  }

  public get preLoginAuthConfig() {
    return this._preLoginAuthConfig;
  }

  public get clientId() {
    return this._clientId;
  }

  public get environment() {
    return this._environment;
  }

  public get scopes() {
    return this._scopes;
  }
}
