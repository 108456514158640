export const limitCharacters = (text: string, maxLength: number): string => {
  if (!text || maxLength < 0) return '';

  if (text.length <= maxLength) {
    return text.trim();
  }

  const truncatedText = text.slice(0, maxLength);

  const lastSpaceIndex = truncatedText.lastIndexOf(' ');

  if (lastSpaceIndex !== -1) {
    return `${truncatedText.slice(0, lastSpaceIndex)}...`;
  }

  return `${truncatedText}...`;
};
