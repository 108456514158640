import styled from 'styled-components';
import { Row, media, Col } from '@nab/nui-react';
import { rem } from 'polished';

export const StyledFooter = styled.footer`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.font.size.xxs};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: ${rem('16px')};
`;

export const StyledCol = styled(Col)`
  height: ${rem('64px')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.white};
  ${media.md`
    justify-content: flex-start;
`}
`;

export const StyledRow = styled(Row)`
  ${media.lg`
   justify-content: space-between;
   margin-right: 0;
   margin-left: 0;
`}
`;
