import styled from 'styled-components';
import { media, easeIn, fast } from '@nab/nui-react';

export const ScaffoldContainer = styled.div`
  width: 100%;
`;

export const LeftPanel = styled.div`
  background: ${props => props.theme.color.grey10};
  width: 300px;
  min-height: 100vh;
  position: fixed;
  z-index: 10000;
  transform: translateX(0%);
  transition: all ${easeIn} ${fast};
  transition-property: transform, width;

  ${media.md`
        width: 400px;
        max-width: 400px;
    `}

  ${media.xl`
        width: 30%;
        max-width: 30%;
    `}

    &.isClosed {
    transform: translateX(-100%);
  }
`;

export const LeftPanelScrollWrapper = styled.div`
  height: auto;
  overflow-y: scroll;
  max-height: calc(100vh - 76px);
`;

export const LeftPanelButtons = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.color.primary};

  .sidemenu-button {
    border-radius: 0px;
  }

  .btn-toggle {
    background: ${props => props.theme.color.primary};
  }

  .btn-home {
    background: ${props => props.theme.color.slate};
  }
`;

export const RightPanel = styled.div`
  width: 100%;
  position: absolute;
  left: 0px;
  min-height: 100vh;
  z-index: 0;
  transition: all ${easeIn} ${fast};
  transition-property: left, width;

  ${media.md`
        width: calc(100% - 400px);
        left: 400px;
    `}

  ${media.xl`
        width: calc(100% - 30%);
        left: 30%;
    `}

    &.isExpanded {
    width: 100% !important;
    left: 0px !important;
  }
`;

export const LeftPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  height: 56px;
`;
