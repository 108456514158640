import { configMgr } from 'lib/configMgr';

import {
  TenantCore,
  TenantEvents,
  TenantEffects,
  TenantHelpers,
  TenantSession,
  ITenantCore,
  ITenantHelpers,
  ITenantSession,
  ITenantEffects,
  ITenantEvents
} from './lib';

export class TenantStrategy {
  private _name: string;
  private _core: ITenantCore;
  private _session: ITenantSession;
  private _effects: ITenantEffects;
  private _events: ITenantEvents;
  private _helpers: ITenantHelpers;

  constructor() {
    this._name = configMgr.get('TENANCY_NAME')?.toLowerCase();
    this._core = new TenantCore();
    this._session = new TenantSession();
    this._events = new TenantEvents();
    this._effects = new TenantEffects();
    this._helpers = new TenantHelpers();
  }

  /**
   * Get the tenancy name
   */
  public get name() {
    return this._name;
  }

  public get core() {
    return this._core;
  }

  protected set core(core: TenantCore) {
    this._core = core;
  }

  public get session() {
    return this._session;
  }

  protected set actions(session: TenantSession) {
    this._session = session;
  }

  public get events() {
    return this._events;
  }

  protected set events(events: TenantEvents) {
    this._events = events;
  }

  public get effects() {
    return this._effects;
  }

  protected set effects(effects: TenantEffects) {
    this._effects = effects;
  }

  public get helpers() {
    return this._helpers;
  }

  protected set helpers(helpers: TenantHelpers) {
    this._helpers = helpers;
  }
}
