import React, { useMemo } from 'react';
import { Container } from '@nab/nui-react';

import { Tenant } from 'tenants/_strategy';

import { constants } from 'tenants/constants';

import { StyledCol, StyledRow, StyledFooter } from './Footer.style';

const Footer: React.FC = () => {
  const footerConfig = useMemo(() => {
    return Tenant.instance().core.footerConfig();
  }, []);

  return (
    <StyledFooter data-testid="footer-wrapper">
      <Container>
        <StyledRow>
          <StyledCol sm="12" md="9">
            {footerConfig.text || constants.ib.LANGUAGE.labels.COPYRIGHT_TEXT}
          </StyledCol>
        </StyledRow>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
