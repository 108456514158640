import { httpClient } from 'lib/httpClient';

export const requestLogout = (url: string) => {
  httpClient({
    url,
    method: 'get',
    'axios-retry': { retries: 2 }
  }).catch(err => {
    return err;
  });
};
