import { IBrandConfig } from '@nab/x-spa-react';

export interface IBBrandConfigWithDafTenancy extends IBrandConfig {
  dafTenancy: string;
}

// NAB Portal Pay Events

export enum SCREEN_TO_RENDER {
  PAYMENT_SCHEDULE = 'payment_schedule',
  PAYMENT_HISTORY = 'payment_history',
  SCHEDULE_PAYMENT_DETAIL = 'schedule_payment_detail',
  HISTORICAL_PAYMENT_DETAIL = 'historical_payment_detail',
  MAKE_PAYMENT = 'make_payment',
  PAYEE_HOME = 'payee_home',
  PAYEE_DETAIL = 'payee_detail',
  WALLET_HOME = 'wallet_home',
  PAYMENT_METHOD_DETAIL = 'payment_method_detail'
}

export type INabPortalPayEventSchema = {
  /**
   * Unique reference number for mapping a Payer to a payee
   */
  payerReferenceNumber?: string;
  /**
   * Unique ID to identify a payment method associated with the Payer
   */
  paymentMethodId?: string;
  /**
   *Unique ID to identify a payment transaction made with the Payer
   */
  paymentId?: string; // For payment details
  /**
   * Unique ID to identify a payment scheduled associated with the Payer
   */
  paymentScheduleId?: string;
  /**
   * String to identify which screen to render on load
   */
  screenToRender?: SCREEN_TO_RENDER;
};

export enum ERROR_CODE {
  UNAUTHORISED = 201029
}

export enum CHANNEL_TYPES {
  SDFD_SHELL = 'APP-19421',
  LAUNCHPAD = 'APP-19477'
}

export type IAppConfig = {
  channelInfo?: {
    channelType: CHANNEL_TYPES;
  };
};

export type IAppState = {
  eventData?: INabPortalPayEventSchema;
};

export interface INppEventDataProps {
  appConfig: Object;
  appState: Object;
}
