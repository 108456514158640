import React, { useCallback } from 'react';
import { getShellActions } from '@nab/x-spa-react';
import { Form, FormInput, FormItem, FormTextArea, Button } from '@nab/nui-react';
import styled from 'styled-components';

const EventDispatcher: React.FC = () => {
  const { dispatchEvent } = getShellActions();

  const onSubmitHandler = useCallback(
    e => {
      if (!e.isValid) return;

      if (e.values.eventSchema) dispatchEvent(e.values.eventName, JSON.parse(e.values.eventSchema));
      else dispatchEvent(e.values.eventName);
    },
    [dispatchEvent]
  );

  return (
    <>
      <Form initialValues={{}} onSubmit={onSubmitHandler}>
        <FormItem name="eventName" label="Event Name" required>
          {props => <FormInput {...props} />}
        </FormItem>
        <FormItem name="eventSchema" label="Schema">
          {props => <FormTextArea {...props} height={'medium'} />}
        </FormItem>
        <Spacer />
        <Button type="submit" variant="primary" label="Dispatch" />
      </Form>
    </>
  );
};

export default EventDispatcher;

const Spacer = styled.div`
  height: 1rem;
`;
