import { LoggerCategoryEnum, LoggerLevelEnum, consoleTransport } from '@nab/nab-x-sdk-browser';

export const settings = {
  dev: {
    transports: {
      [LoggerCategoryEnum.default]: consoleTransport,
      [LoggerCategoryEnum.analytics]: consoleTransport,
      [LoggerCategoryEnum.diagnostic]: consoleTransport,
      [LoggerCategoryEnum.api]: consoleTransport,
      [LoggerCategoryEnum.ui]: consoleTransport
    },
    appName: 'nab-x-core-shell.frontend',
    appVersion: process.env.REACT_APP_VERSION || 'not-set!!!',
    autoPostFrequency: 5000,
    consoleLevel: LoggerLevelEnum.log,
    platform: 'nab-x',
    environment: 'dev'
  },
  prod: {
    transports: {
      [LoggerCategoryEnum.default]: consoleTransport,
      [LoggerCategoryEnum.analytics]: consoleTransport,
      [LoggerCategoryEnum.diagnostic]: consoleTransport,
      [LoggerCategoryEnum.api]: consoleTransport,
      [LoggerCategoryEnum.ui]: consoleTransport
    },
    appName: 'nab-x-core-shell.frontend',
    appVersion: process.env.REACT_APP_VERSION || 'not-set!!!',
    autoPostFrequency: 30000,
    logLevel: LoggerLevelEnum.log,
    consoleLevel: undefined,
    platform: 'nab-x',
    environment: 'prod'
  }
};
