import { useUser } from '@nab/x-spa-react';
import { useCallback } from 'react';
import { Tenant } from 'tenants/_strategy';

export const useLogoutCleanup = () => {
  const user = useUser();

  const logoutUser = useCallback(
    async (redirectUri?: string, emitAnalytics?: boolean) => {
      await Tenant.instance().effects.logout(user, redirectUri, emitAnalytics);
    },
    [user]
  );

  return { logoutUser };
};
