import { DafExchangeStrategy } from '@nab/x-spa-react';

import { TOKEN_EXCHNAGE_MODE } from './ib-token.constants';

export class IBDafExchangeStrategy extends DafExchangeStrategy {
  supports(config) {
    const { isSeed, tokenExchangeConfig } = config;
    return (
      super.supports(config) && isSeed === false && tokenExchangeConfig?.tokenExchangeMode !== TOKEN_EXCHNAGE_MODE.JWT
    );
  }
}
