import React, { useCallback, useMemo } from 'react';

import { MiniAppInstance } from '@nab/x-spa-react';

import { GenericError, AppLoading } from 'components/common';

import { ISpaProps } from 'tenants/types';

import { AppViewport } from './Viewport.style';

export interface ViewportProps extends ISpaProps {
  errorTitle?: string;
  iframeCountRef?: number;
  errorSlotOverride?: Function;
}

const Viewport: React.FC<ViewportProps> = ({ activeAppInstance, errorTitle, errorSlotOverride }) => {
  const GenericErrorComponent = useCallback(() => {
    if (errorSlotOverride) {
      return errorSlotOverride();
    }
    return <GenericError errorTitle={errorTitle} />;
  }, [errorSlotOverride, errorTitle]);

  return useMemo(() => {
    return (
      <AppViewport>
        {activeAppInstance && (
          <MiniAppInstance appInstance={activeAppInstance} loadingSlot={AppLoading} errorSlot={GenericErrorComponent} />
        )}
      </AppViewport>
    );
  }, [activeAppInstance, GenericErrorComponent]);
};
export default Viewport;
