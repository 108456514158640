import styled, { createGlobalStyle } from 'styled-components';

export const StyledFonts = createGlobalStyle`
  @font-face {
    font-family: 'Epilogue';
    src: url(static/fonts/epilogue-Semibold.woff2) format('woff2');
  }
`;

export const StyledRoot = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const StyledContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

export const MenuAndViewport = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

export const AppViewport = styled.div`
  display: flex;
  position: relative;
  flex: 1;

  > div {
    width: 100%;
    height: 100%;
  }

  // This ensures apps are no longer displayed once they start shutting down
  > [aria-hidden='true'] {
    display: none;
  }
`;
