import { useFlag } from '@nab/nab-x-react';
import { limitCharacters } from 'helpers/limitCharacters';
import { useMemo } from 'react';
import { Tenant } from 'tenants/_strategy';
import { constants } from 'tenants/constants';

export interface NotificationMessage {
  title: string;
  body: string;
}

export const useNotificationBanner = () => {
  const tenant = Tenant.instance().name;

  const characterMaxLength = constants.common.BANNER_CHARACTERS_LIMITED;
  const { PRE_LOGIN_DISPLAY_BANNER, PRE_LOGIN_BANNER_MESSAGE } = constants.common.LAUNCHDARKLY_FLAGS;

  const displayBanner = useFlag<boolean>(`${PRE_LOGIN_DISPLAY_BANNER}-${tenant}`, false);
  const bannerMessage = useFlag<NotificationMessage>(`${PRE_LOGIN_BANNER_MESSAGE}-${tenant}`, { title: '', body: '' });

  return useMemo(() => {
    const { title, body } = bannerMessage;
    const bannerTitle = title && limitCharacters(title, characterMaxLength);
    const bannerBody = title && body && limitCharacters(body, characterMaxLength - bannerTitle.length);

    return {
      showNotificationBanner: displayBanner && bannerTitle?.length > 0,
      bannerTitle,
      bannerBody
    };
  }, [displayBanner, bannerMessage]);
};
