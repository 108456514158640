/* eslint-disable max-statements */
import { IUser, getShellActions } from '@nab/x-spa-react';
import Cookies from 'js-cookie';
import { configMgr } from 'lib/configMgr';

import { constants } from 'tenants/constants';

import { Tenant, TenantEvents } from 'tenants/_strategy';

export class Events extends TenantEvents {
  public callToRequestLogin = async (user: IUser, bearerToken: string) => {
    if (bearerToken) {
      let csid, gbd;

      try {
        csid = await user.dataSecurity('bc').getData();
        gbd = await user.dataSecurity('gbd').getData();
      } catch (e) {
        // log error but still proceed to log in
        console.error('BC and GBD', csid, gbd);
      }

      const postParams = {
        Authorization: bearerToken,
        browserData: gbd,
        customerSessionId: csid
      };

      /**
       * PREVIOUSLY LIKE:
       * const actionUrl = configMgr.get('IB_CLASSIC_LOGIN') + window.location.search;
       * Removed window.location.search due to xss vulnerability. Evaluate which query params
       * are needed and parse window.location.search accordingly.
       */
      const actionUrl = configMgr.get('IB_CLASSIC_LOGIN');

      const form = document.createElement('form');
      form.method = constants.ib.FORM_METHOD_POST;
      form.action = actionUrl;

      for (const key in postParams) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', constants.ib.FORM_FIELD_TYPE_HIDDEN);
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', postParams[key]);
        form.appendChild(hiddenField);
      }

      document.body.appendChild(form);
      form.submit();
    }
  };

  public async previewEnvPermissionCheck(event: any, user: IUser) {
    const { updateApps } = getShellActions();
    const authenticatedLDUser: Partial<IUser> = {
      id: event.ssc,
      idHash: event.ssc,
      authenticated: true,
      context: {
        updateLDContext: true
      },
      details: event.identityInfo
    };
    try {
      await updateApps({ user: authenticatedLDUser });
      return user.tailoring.checkFlag(constants.ib.LAUNCHDARKLY_FLAGS.ACCESS_TO_IB_SHELL, false);
    } catch (error) {
      return false;
    }
  }

  public override async handleIdentityMiniappEventAuthenticated(event: any, user: IUser) {
    // Null checking bearerToken is necessary in case the handler is executed without any event
    if (event?.bearerToken && /\/login/.test(window.location.pathname)) {
      const { navigate } = getShellActions();

      Cookies.set(constants.ib.SESSION_COOKIE, event[constants.common.BEARER_TOKEN], {
        path: constants.ib.COOKIE_PATH
      });
      Cookies.set(constants.ib.SESSION_TIMESTAMP, `${Date.now()}`);
      Cookies.set(constants.ib.COOKIE_IS_AUTHENTICATED, `true`, { path: '/' });
      Cookies.set(constants.ib.DAF_SSC_HASH, event.ssc);
      Cookies.set(constants.ib.CAN_SWITCH, event.canSwitch ? 'true' : 'false');
      Cookies.set(constants.ib.IDENTITY_INFO, JSON.stringify(event.identityInfo) || '{}');

      window.sessionStorage.setItem(constants.ib.IS_FIRST_LOGIN, 'Y');
      window.sessionStorage.setItem(constants.ib.IS_USER_LOGGED_IN, 'Y'); // added this to avoid multiple logins when page reload.
      if (Tenant.instance().helpers.isPreviewEnv(user) && !!event.ssc) {
        const hasAccessPermission = await this.previewEnvPermissionCheck(event, user);
        if (!hasAccessPermission) {
          await Tenant.instance().effects.logout(
            user,
            `/login?error=${constants.ib.PREVIEW_ACCESS_ERROR_EVENT_DISPLAY_CODE}`
          );
          return;
        }
      }
      if (event.canLink) {
        navigate(constants.ib.LINKAGE_PATH);
      } else {
        await this.callToRequestLogin(user, event[constants.common.BEARER_TOKEN]);
      }
    }
  }

  /**
   * Handles the process following the Linkage Miniapp ending
   * @param event     The event received by the listener
   * @param user      The user object obtained form the MiniAppContext
   */
  public async handleLinkageMiniappLinkageEnded?(event: any, user: IUser) {
    Cookies.set(constants.ib.CAN_SWITCH, event?.canSwitch ? 'true' : 'false');
    if (Cookies.get(constants.ib.SESSION_COOKIE)) {
      await this.callToRequestLogin(user, Cookies.get(constants.ib.SESSION_COOKIE));
    }
  }

  /**
   * Handles the process following the Linkage Miniapp ending
   * @param event           The event received by the listener
   * @param logoutUser      The function to logout the user
   */
  public async handleLinkageMiniappLinkageError(event: any, logoutUser: Function) {
    logoutUser(`/login?error=${constants.ib.LINKAGE_ERROR_EVENT_DISPLAY_CODE}`);
  }

  public override handleLogoutButton(logoutUser: Function): void {
    const redirectUrlAfterLogout = configMgr.get('LOGOUT_REDIRECT_URL');
    if (Tenant.instance().effects.confirmLogout()) {
      logoutUser(redirectUrlAfterLogout);
    }
  }

  public get ref() {
    return {
      ...super.ref,
      linkage: {
        USER_LINKING_ENDED: 'user_linking_ended',
        USER_LINKING_ERROR: 'user_linking_error'
      }
    };
  }
}
