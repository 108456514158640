import { useMemo } from 'react';

/**
 * To access window.location.pathname in React Hook, use this hook.
 * This is to overcome the issue that useHistory() hook from NabX is causing trailing slash in /login,
 * resulting in /login/, which in turns causes login failure.
 */
export const useReactPath = () => {
  const path = useMemo(() => window.location.pathname, []);
  return path;
};
