import React, { useState } from 'react';
import styled from 'styled-components';
import { ChevronDownIcon, ButtonIcon, easeOut, fast } from '@nab/nui-react';

const AccordionWrapper = styled.div`
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 5px 15px;
  color: ${props => props.theme.color.white};
  background: ${props => props.theme.color.grey30};
`;

const AccordionContent = styled.div`
  padding: 10px;
  background: ${props => props.theme.color.white};
  transform: scaleY(1);
  transform-origin: top;
  transition: all ${easeOut} ${fast};
  transition-property: max-height, transform, padding;

  &.isClosed {
    padding: 0px;
    max-height: 0px;
    overflow: hidden;
    transform: scaleY(0);
  }
`;

export interface AccordionProps {
  title?: string;
  children: React.ReactNode;
}

const AccordionToggle: React.FC<{ isClosed: boolean; onClick?: (...params) => void }> = ({ isClosed, ...rest }) => {
  return <ButtonIcon color={'white'} isOnDarkBg label="Accordion Toggle" icon={ChevronDownIcon} {...rest} />;
};

const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
  const [toggleState, setToggleState] = useState(false);

  return (
    <AccordionWrapper>
      <AccordionHeader>
        {title}
        <AccordionToggle
          isClosed={toggleState}
          onClick={() => {
            setToggleState(!toggleState);
          }}
        />
      </AccordionHeader>
      <AccordionContent className={toggleState && 'isClosed'}>{children}</AccordionContent>
    </AccordionWrapper>
  );
};

export default Accordion;
