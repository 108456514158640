import { Button, H1 } from '@nab/nui-react';
import { useActions } from '@nab/x-spa-react';
import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { Tenant } from 'tenants/_strategy';
import { IbStrategy } from 'tenants/internet-banking';

const LinkageStub: React.FC = () => {
  const { dispatchEvent } = useActions();

  const tenant = Tenant.instance() as IbStrategy;

  const handleEndWithNull = () => {
    return dispatchEvent(tenant.events.ref.linkage.USER_LINKING_ENDED, null);
  };

  const handleEndWithCanSwitch = () => {
    return dispatchEvent(tenant.events.ref.linkage.USER_LINKING_ENDED, { canSwitch: true });
  };

  const handleError = () => {
    return dispatchEvent(tenant.events.ref.linkage.USER_LINKING_ERROR, {
      errorId: 123,
      errorMessage: 'Linkage Failed'
    });
  };

  return (
    <>
      <StyledH1>Linkage Miniapp Stub</StyledH1>
      <StubWrapper>
        <StyledButton label="end with null" onClick={handleEndWithNull}></StyledButton>
        <StyledButton label="end with canSwitch" onClick={handleEndWithCanSwitch}></StyledButton>
        <StyledButton label="error" onClick={handleError}></StyledButton>
      </StubWrapper>
    </>
  );
};

export default LinkageStub;

const StubWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin: ${rem('30px')};
`;

const StyledH1 = styled(H1)`
  margin-top: ${rem('15px')};
  text-align: center;
`;
