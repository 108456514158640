import { AppDRUMStrategy, DigitalDataStrategy, LaunchDarklyFlagStrategy } from '@nab/nab-x-sdk-browser';
import {
  DafInMemoryCacheStrategy,
  DafAnonymousAuthStrategy,
  DefaultAuthConfig,
  ReactRootInitConfig
} from '@nab/x-spa-react';
import { Tenant } from 'tenants/_strategy';
import { AuthConfigs } from 'lib/auth-strategies/auth.config';
import { configMgr } from 'lib/configMgr';
import GlobalSpa from 'components/GlobalSpa/GlobalSpa';
import { IBDafExchangeStrategy } from 'lib/auth-strategies/ib-daf-exchange-strategy';

import { CookieAuthStrategy } from 'lib/auth-strategies/cookie-auth-strategy';

import { constants } from 'tenants/constants';

import { IBBrandConfigWithDafTenancy } from '../types';

export const initConfig = async (): Promise<ReactRootInitConfig> => {
  const isAuthenticated = Tenant.instance().session.isValid();
  const userId = Tenant.instance().session.userId();

  const environment = configMgr.get('ENVIRONMENT'); // 'prod', 'sit1', 'dev', 'local' etc

  const authConfigs = AuthConfigs.getInstance({
    environment,
    clientId: configMgr.get('DAF_AUTH_CLIENT_ID'),
    scopes: configMgr.get('DAF_AUTH_SCOPE')
  });

  const dafAuthUrl = `${configMgr.get('DAF_ENDPOINT')}/v1/idp/oauth/token`;

  return {
    // React component which provides the main layout for the shell
    rootComponent: GlobalSpa,

    // Named set of apps and menu items which live in this shell
    rootContextId: 'root',

    // Default auth config inherited by all apps which don't explicitly override it
    authConfig: isAuthenticated
      ? (authConfigs.postLoginAuthConfig as DefaultAuthConfig)
      : (authConfigs.preLoginAuthConfig as DefaultAuthConfig),

    authStrategies: [
      new DafAnonymousAuthStrategy(dafAuthUrl, authConfigs.clientId),
      new CookieAuthStrategy(authConfigs.postLoginAuthConfig),
      new IBDafExchangeStrategy(dafAuthUrl)
    ],

    // Defines how tokens are cached & validated
    authTokenCacheStrategy: new DafInMemoryCacheStrategy(),

    // Defines how context config is retrieved (eg. from an API, JSON file on disk etc...)
    spaContextProvider: Tenant.instance().core.spaContextProvider,

    userConfig: {
      authenticated: isAuthenticated,
      id: userId,
      org: 'NAB',
      type: 'customer',
      sessionId: null,
      context: {
        shell: constants.common.CUSTOMER_USER_TYPE
      }
    },

    servicesConfig: {
      rum: {
        strategy: new AppDRUMStrategy({
          libraryUrl: configMgr.get('APPDRUM_SCRIPT_TAG_SRC'),
          configValue: {
            appKey: configMgr.get('APPDRUM_APP_KEY'),
            adrumExtUrlHttp: configMgr.get('APPDRUM_EXT_URL_HTTP'),
            adrumExtUrlHttps: configMgr.get('APPDRUM_EXT_URL_HTTPS'),
            beaconUrlHttp: configMgr.get('APPDRUM_BEACON_URL_HTTP'),
            beaconUrlHttps: configMgr.get('APPDRUM_BEACON_URL_HTTPS'),
            xd: {
              enable: true
            },
            spa: {
              spa2: true
            }
          }
        })
      },

      analytics: {
        strategy: new DigitalDataStrategy()
      },
      flags: {
        // NAB Portal Pay LaunchDarkly Key
        strategy: new LaunchDarklyFlagStrategy(configMgr.get('LAUNCH_DARKLY_API_KEY', 'not-set!!!')),
        apiKey: '',
        initialData: undefined
      },
      interactionPrefix: constants.npp.INTERACTION_PREFIX
    },

    // Defines brandId and themeName, and miniapps will read it.
    brand: {
      id: constants.npp.BRAND_ID,
      themeName: constants.npp.THEME_NAME,
      dafTenancy: constants.npp.DAF_TENANCY
    } as IBBrandConfigWithDafTenancy,

    // Where should the shell be rendered?
    hostElement: document.querySelector('#appRoot')
  };
};
