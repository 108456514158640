import { useActions } from '@nab/x-spa-react';
import { useEffect, useState } from 'react';
import { Tenant } from 'tenants/_strategy';

export const useIdentityLoaded = () => {
  const { addEventListener } = useActions();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const unsubscribe = addEventListener(Tenant.instance().events.ref.identity.MINIAPP_LOADED_IDENTITY, () => {
      setLoaded(true);
    });
    return () => unsubscribe();
  }, [addEventListener]);
  return loaded;
};
