// @ts-nocheck
import React, { useCallback, useState } from 'react';
import { useActions } from '@nab/nab-x-react';
import { Form, FormInput, FormItem, FormPassword, Link, FormMessage, Paragraph } from '@nab/nui-react';

import { Tenant } from 'tenants/_strategy';
import { httpClient } from 'lib/httpClient';
import { configMgr } from 'lib/configMgr';
import { constants } from 'tenants/constants';

import { FormWrapper, StyledIdentityStubHeading, StyledIdentityStubLoginButton } from './IdentityStub.style';

import NabPortalPayCreateProfileStub from './NabPortalPayCreateProfileStub';

const NabPortalPayIdentityStub: React.FC = () => {
  const { dispatchEvent } = useActions();
  const [error, setError] = useState<string>();
  const [email, setEmail] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isCreateProfile, setIsCreateProfile] = useState<Boolean>(false);

  const [txnId, setTxnId] = useState<string>();
  const [challengeToken, setChallengeToken] = useState<string>();
  const [oneTimePassword, setOneTimePassword] = useState<string>();
  const [promptOneTimePassword, setPromptOneTimePassword] = useState<boolean>(false);

  const authHandler = useCallback(
    async (param: { email: string; mobile: string; password: string }) => {
      try {
        const DAF_AUTH_CLIENT_ID = configMgr.get('DEV_DAF_AUTH_CLIENT_ID', 'not-set!!');
        const DEV_DAF_ENDPOINT = configMgr.get('DEV_DAF_ENDPOINT', 'https://sit1.customer.api.extnp.national.com.au');
        // const SMS_STUB_ENDPOINT = configMgr.get(
        //   'DEV_SMS_STUB_ENDPOINT',
        //   'https://dev2.da-cse.daf.awsnp.national.com.au/dastubs/ccom/notif/v2/destination'
        // );

        // Get the challenge bearer token (acr: R2)
        const challengeBearerToken: any = await httpClient({
          url: `${DEV_DAF_ENDPOINT}/v1/idp/oauth/token`,
          method: 'post',
          data: {
            client_id: DAF_AUTH_CLIENT_ID,
            grant_type: 'nab:usernamePassword',
            username: param.email,
            password: param.password,
            tenant: 'baker',
            scope: 'idp:user nabx:nav-manager'
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const challengeToken = challengeBearerToken.data.bearerToken;
        // We already verify via OTP before
        if (challengeBearerToken.data.acr === '2') {
          return dispatchEvent(Tenant.instance().events.ref.identity.USER_AUTHENTICATED, {
            [constants.common.BEARER_TOKEN]: challengeToken,
            identityInfo: {
              email: param.email
            }
          });
        }

        setChallengeToken(challengeToken);

        // Invoke Challenge
        const challenge: any = await httpClient({
          url: `${DEV_DAF_ENDPOINT}/v2/idp/oauth/challenge`,
          method: 'post',
          data: {
            reauthMethod: 'OTP',
            delivery: ['SMS'],
            grant_type: 'nab:reauthentication'
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${challengeToken}`
          }
        });

        setTxnId(challenge.data.txnId);

        setPromptOneTimePassword(true);

        // Get challenge code from SMS stub
        // const code = await httpClient({
        //   url: `${SMS_STUB_ENDPOINT}/${param.mobile}`,
        //   method: 'get'
        // });

        // const otp = code.data.request.message.body.replace(/\D+/g, ''); // extract otp from body
      } catch (e) {
        console.error(e);
        setError('Failed to get session token from DAF!');
      }
    },
    [dispatchEvent]
  );

  const reauthHandler = useCallback(
    async (param: { otp: string }) => {
      const DAF_AUTH_CLIENT_ID = configMgr.get('DEV_DAF_AUTH_CLIENT_ID', 'not-set!!');
      const DEV_DAF_ENDPOINT = configMgr.get('DEV_DAF_ENDPOINT', 'https://sit1.customer.api.extnp.national.com.au');

      // Re-auth to get the session token (acr: 2)
      const reauth: any = await httpClient({
        url: `${DEV_DAF_ENDPOINT}/v1/idp/oauth/token`,
        method: 'post',
        data: {
          client_id: DAF_AUTH_CLIENT_ID,
          grant_type: 'nab:reauthentication',
          token: challengeToken,
          reauthMethod: 'OTP',
          txnId,
          otp: oneTimePassword,
          scope: 'idp:user nabx:nav-manager'
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const bearerToken = reauth.data.bearerToken;

      return dispatchEvent(constants.common.EVENT_USER_AUTHENTICATED, {
        [constants.common.BEARER_TOKEN]: bearerToken,
        identityInfo: {
          email: param.email
        }
      });
    },
    [challengeToken, dispatchEvent, txnId, oneTimePassword]
  );

  return (
    <FormWrapper>
      <StyledIdentityStubHeading variant="impact">NAB Portal Pay</StyledIdentityStubHeading>
      {process.env.NODE_ENV === 'development' && (
        <div style={{ marginBottom: '2rem' }}>
          <Link variant="standard" onClick={() => setIsCreateProfile(x => !x)} color="primary">
            {isCreateProfile ? 'Back to login' : 'Create a profile'}
          </Link>
        </div>
      )}
      {process.env.NODE_ENV === 'development' && isCreateProfile ? (
        <NabPortalPayCreateProfileStub
          setIsCreateProfile={setIsCreateProfile}
          setLoginEmail={setEmail}
          setLoginMobile={setMobile}
        />
      ) : promptOneTimePassword ? (
        <>
          <Form
            initialValues={undefined}
            method="post"
            onSubmit={props => {
              if (props.isValid) {
                reauthHandler({ otp });
              }
            }}
          >
            <FormItem
              name="otp"
              label={'OTP'}
              value={oneTimePassword}
              required
              data-testid="otp"
              onChange={event => {
                setOneTimePassword(event.target.value);
              }}
              validations={[{ name: 'required', message: 'Enter your OTP' }]}
            >
              {props => <FormInput {...props} autoComplete="off" />}
            </FormItem>
            <StyledIdentityStubLoginButton
              type="submit"
              variant="primary"
              label={constants.common.LOGIN_BUTTON_LABEL}
            />
          </Form>
        </>
      ) : (
        <>
          <Form
            initialValues={undefined}
            method="post"
            onSubmit={props => {
              if (props.isValid) {
                authHandler({ email, mobile, password });
              }
            }}
          >
            <FormItem
              name="email"
              label={'Email'}
              value={email}
              required
              data-testid="email"
              onChange={event => {
                setEmail(event.target.value);
              }}
              validations={[{ name: 'required', message: 'Enter your email' }]}
            >
              {props => <FormInput {...props} autoComplete="off" />}
            </FormItem>

            <FormItem
              name="mobile"
              label={'Mobile'}
              value={mobile}
              required
              data-testid="mobile"
              onChange={event => {
                setMobile(event.target.value);
              }}
              validations={[{ name: 'required', message: 'Enter your mobile' }]}
            >
              {props => <FormInput {...props} autoComplete="off" />}
            </FormItem>

            <FormPassword
              name="password"
              label={'Password'}
              value={password}
              required
              data-testid="password"
              onChange={event => {
                setPassword(event.target.value);
              }}
            />
            <StyledIdentityStubLoginButton
              type="submit"
              variant="primary"
              label={constants.common.LOGIN_BUTTON_LABEL}
            />
          </Form>
          <br />
          {error && (
            <FormMessage variant="warning" title="Oops!" focused={true}>
              <Paragraph>{error}</Paragraph>
              <br />
            </FormMessage>
          )}
          <br />
          <Link variant="standard" href="/register" color="primary">
            Register a profile
          </Link>
        </>
      )}
    </FormWrapper>
  );
};

export default NabPortalPayIdentityStub;
