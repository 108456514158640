import styled from 'styled-components';
import { Button, H1 } from '@nab/nui-react';
import { rem } from 'polished';

export const FormWrapper = styled.div`
  padding: ${rem('30px')};
`;

export const StyledIdentityStubLoginButton = styled(Button)`
  margin-top: ${rem('30px')};
`;

export const StyledIdentityStubHeading = styled(H1)`
  margin-bottom: ${rem('30px')};
`;
