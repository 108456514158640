import styled from 'styled-components';

export const StyledContentContainer = styled.div`
  max-width: 503px;
  padding-top: 4px;
`;

export const StyledMainWrapper = styled.main<{ imageUrl: string }>`
  position: relative;
  min-height: calc(100vh - 3.5rem - 4rem - 3.1rem);
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;

  @media screen and (max-width: ${({ theme }) => theme.grid.breakpoints.lg}) {
    min-height: calc(100vh - 3.5rem - 4rem);
  }
`;
