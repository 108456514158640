import { MutableRefObject } from 'react';

import { waitFor } from './waitFor';

const IDENTITY_MINIAPP: string = 'nab-idp-password';
const MINIAPP_IFRAME: string = 'miniapp-iframe';

export const setFocus = async (
  mainRef: MutableRefObject<any>,
  appTag: string,
  skipToMainContent: boolean = false,
  shadowRootTrials: number = 0
) => {
  const getMainRoot = (appTag: string) => {
    switch (appTag) {
      case IDENTITY_MINIAPP:
        return mainRef.current;
      case MINIAPP_IFRAME:
        return mainRef.current?.querySelector(appTag);
      default:
        return mainRef.current?.querySelector(appTag)?.shadowRoot;
    }
  };

  const mainRoot = getMainRoot(appTag);
  if (shadowRootTrials === 15) return; // allow 3.5secs to load shadowRoot
  if (!mainRoot) {
    await waitFor(250);

    return setFocus(mainRef, appTag, skipToMainContent, ++shadowRootTrials);
  }

  mainRef.current.focus({ preventScroll: true, focusVisible: false });

  let h1: any;
  let h1Trials: number = 0;

  while ([undefined, null].includes(h1) && h1Trials <= 50) {
    // allow up to 5 seconds to get the h1 in dom
    await waitFor(100);

    h1 =
      appTag === MINIAPP_IFRAME
        ? mainRoot?.querySelector('iframe')?.contentDocument?.querySelector('h1')
        : mainRoot?.querySelector('h1');
    h1Trials++;
  }

  if (h1 && (appTag === MINIAPP_IFRAME || skipToMainContent)) {
    if (appTag === MINIAPP_IFRAME) {
      const doc = mainRoot.querySelector('iframe').contentDocument;
      const head = doc.querySelector('head');
      const style = doc.createElement('style');
      style.innerHTML = `h1:focus{outline:none!important; border:none!important;}`;
      head.appendChild(style);
    }

    h1.tabIndex = -1;
    h1.focus({ preventScroll: true, focusVisible: false });
  }
};
