import React from 'react';
import type { App } from '@nab/x-spa';

export interface LoadingSlotProps {
  app: App;
}

// Do not want to show Loading state. Ref US1704792.
const AppLoading: React.FC<LoadingSlotProps> = () => {
  return <div />;
};

export default AppLoading;
