import { getShellActions } from '@nab/x-spa-react';
import { TenantEvents } from 'tenants/_strategy';
import { CHANNEL_TYPES, IAppConfig, IAppState, INppEventDataProps } from '../types';

export class Events extends TenantEvents {
  public override handleEventRoute(event: any, eventName: string) {
    const { navigate } = getShellActions();
    const routeTo = this.eventToRouteMap[eventName];

    const eventData = {
      appConfig: {
        channelInfo: {
          channelType: CHANNEL_TYPES.SDFD_SHELL
        }
      } as IAppConfig,
      appState: {
        eventData: event
      } as IAppState
    } as INppEventDataProps;

    navigate(routeTo, eventData);
  }

  private get eventToRouteMap() {
    return {
      // Payments Miniapp Routes
      [this.ref.payment.MAKE_PAYMENT]: `/portal/payments`,
      [this.ref.payment.PAYMENT_DETAIL]: `/portal/payments`,
      [this.ref.payment.SHOW_MORE]: `/portal/payments`,
      [this.ref.payment.PORTAL_PAY_HOME_PAGE]: `/portal/home`,
      [this.ref.payment.PAYEE_HOME_PAGE]: `/portal/payees`,
      [this.ref.payment.WALLET_HOME_PAGE]: `/portal/wallet`,

      // Profile Miniapp Routes
      [this.ref.profile.PORTAL_PAY_HOME_PAGE]: `/portal/home`,
      [this.ref.profile.LOAD_PAYMENTS]: `/portal/payments`,

      // Payees Miniapp Routes
      [this.ref.payee.GO_PAYEE_HOME]: `/portal/payees`,
      [this.ref.payee.PAYEE_DETAIL]: `/portal/payees`,
      [this.ref.payee.PORTAL_PAY_HOME_PAGE]: `/portal/home`,
      [this.ref.payee.LOAD_PAYMENTS]: `/portal/payments`,

      // Wallet Miniapp Routes
      [this.ref.wallet.GO_WALLET_HOME]: `/portal/wallet`,
      [this.ref.wallet.PAYMENT_METHOD_DETAIL]: `/portal/wallet`,
      [this.ref.wallet.PORTAL_PAY_HOME_PAGE]: `/portal/home`,
      [this.ref.wallet.LOAD_PAYMENTS]: `/portal/payments`
    };
  }

  public get ref() {
    return {
      ...super.ref,
      /**
       * BKR Payment Miniapp
       */
      payment: {
        /**
         * Dispatched when Make Payment button is clicked by user when on launchpad screen
         */
        MAKE_PAYMENT: `event:portal_pay:customer_payments:make_payment`,
        /**
         * Dispatched when payment detail button is clicked by user when on launchpad screen
         */
        PAYMENT_DETAIL: `event:portal_pay:customer_payments:payment_detail`,
        /**
         * Dispatched when show more button is clicked by user when on launchpad screen
         */
        SHOW_MORE: `event:portal_pay:customer_payments:show_more`,
        /**
         * Dispatched when go back to home is clicked by user when on payments app screen.
         */
        PORTAL_PAY_HOME_PAGE: `event:portal_pay:customer_payments:portal_pay_home_page`,
        /**
         * Dispatched when go back to payee home page is clicked by user when on payments app screen.
         */
        PAYEE_HOME_PAGE: `event:portal_pay:customer_payments:payee_home_page`,
        /**
         * Dispatched when go back to wallet home page is clicked by user when on payments app screen.
         */
        WALLET_HOME_PAGE: `event:portal_pay:customer_payments:wallet_home_page`
      },

      /**
       * BKR Profile Miniapp
       */
      profile: {
        /**
         * Dispatched when go back to Launchpad home is clicked by user when on Merchant's Customer
         * Profile app home screen
         */
        PORTAL_PAY_HOME_PAGE: `event:portal_pay:customer_profile:launchpad_home_page`,
        /**
         * Dispatched when Manage Scheduled Payments is clicked by user when on close profile screen
         */
        LOAD_PAYMENTS: `event:portal_pay:customer_profile:load_payments`
      },

      /**
       * BKR Payee Miniapp
       */
      payee: {
        /**
         * Dispatched when Manage Payees button or View All Payees is clicked by user when on launchpad screen
         */
        GO_PAYEE_HOME: `event:portal_pay:payees:go_payees_home`,
        /**
         * Dispatched when payee detail button is clicked by user when on launchpad screen
         */
        PAYEE_DETAIL: `event:portal_pay:payees:payee_detail`,
        /**
         * Dispatched when go back to home is clicked by user when on Merchant's Invoice Reference app home screen
         */
        PORTAL_PAY_HOME_PAGE: `event:portal_pay:payees:portal_pay_home_page`,
        /**
         * Dispatched when Manage Scheduled Payments is clicked by user when on delete payees screen
         */
        LOAD_PAYMENTS: `event:portal_pay:payees:load_payments`
      },

      /**
       * BKR Wallet Miniapp
       */
      wallet: {
        /**
         * Dispatched when Manage Wallet button or View all payment methods is clicked by user when on launchpad screen
         */
        GO_WALLET_HOME: `event:portal_pay:wallet:go_wallet_home`,
        /**
         * Dispatched when payment method detail button is clicked by user when on launchpad screen
         */
        PAYMENT_METHOD_DETAIL: `event:portal_pay:wallet:payment_method_detail`,
        /**
         * Dispatched when go back to home is clicked by user when on Merchant's Customer Wallet app home screen
         */
        PORTAL_PAY_HOME_PAGE: `event:portal_pay:wallet:portal_pay_home_page`,
        /**
         * Dispatched when Manage Scheduled Payments is clicked by user when on delete payment method screen
         */
        LOAD_PAYMENTS: `event:portal_pay:wallet:load_payments`
      }
    };
  }
}
