import { useFlagStrategy } from '@nab/nab-x-react';
import { LaunchDarklyFlagStrategy } from '@nab/nab-x-sdk-browser';
import { configMgr } from 'lib/configMgr';

export const useWaitingFlagStrategyReady = () => {
  const isFlagReady = useFlagStrategy(
    new LaunchDarklyFlagStrategy(configMgr.get('LAUNCH_DARKLY_API_KEY', 'not-set!!!'))
  );

  return isFlagReady;
};
