import { useFlag } from '@nab/nab-x-react';
import { Col, Container, Row } from '@nab/nui-react';
import { MiniAppInstance, SpaProps, useActions, useUser } from '@nab/x-spa-react';
import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { configMgr } from 'lib/configMgr';
import { LaunchDarklyFlagStrategy } from '@nab/nab-x-sdk-browser';

import { useWaitingFlagStrategyReady } from 'hooks/useWaitingFlagStrategyReady';
import { useWidget } from 'hooks/useWidget';

import { GenericError } from 'components/common';
import GlobalHeader from 'components/GlobalHeader/GlobalHeader';
import IdentityStub from 'components/LoginSpa/stubs/IdentityStub';
import LoginTipsIframe from 'components/LoginTipsIframe/LoginTipsIframe';
import NotificationBanner from 'components/NotificationBanner/NotificationBanner';

import { constants } from 'tenants/constants';

import { Tenant } from 'tenants/_strategy/Tenant';

import { useDisplayError } from './hooks/useDisplayError';
import { useIdentityLoaded } from './hooks/useIdentityLoaded';
import { useNotificationBanner } from './hooks/useNotificationBanner';
import {
  AppViewport,
  MenuAndViewport,
  StyledIdentityWrapper,
  StyledLogginInnerWrapper,
  StyledLoginWrapper
} from './LoginSpa.style';

export const LoginSpa: React.FC<SpaProps> = ({ activeAppInstance }) => {
  useWaitingFlagStrategyReady();
  useDisplayError();

  const isUsingIdentityStub = configMgr.get('IDENTITY_STUB_ENVIRONMENT') === 'true' ? true : false;

  const [bioCatchCsid, setBioCatchCsid] = useState('');
  const [iframeLoaded, setIframeLoaded] = useState(false);

  //Define authentication flag
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const mainRef = useRef(null);
  const chatRef = useRef(null);
  const axiosInstanceRef = useRef<AxiosInstance>(null);

  const user = useUser();
  const { addEventListener, navigate } = useActions();

  const identityLoaded = useIdentityLoaded();
  const enableLoginTipsIframe = useFlag<boolean>(constants.ib.LAUNCHDARKLY_FLAGS.LOGIN_TIPS_IFRAME, false);

  const { embedWidgetApp } = useWidget();
  const { showNotificationBanner, bannerTitle, bannerBody } = useNotificationBanner();

  if (!axiosInstanceRef.current) {
    axiosRetry((axiosInstanceRef.current = axios.create()));
  }

  // add login handler for Identity miniapp
  useEffect(() => {
    addEventListener(Tenant.instance().events.ref.identity.USER_AUTHENTICATED, event => {
      Tenant.instance().events.handleIdentityMiniappEventAuthenticated(event, user);
      const tenant = configMgr.get('TENANCY_NAME').toLowerCase();
      // This check is here to prevent the logout button from coming up on transfer to IB
      if(!event?.canLink && tenant === constants.common.IB_TENANT) {
        setIsAuthenticated(false);
        return false; 
      } else {
        setIsAuthenticated(true);
      }
      return true;
    });
  }, [user, addEventListener, navigate]);

  // load chat widget
  useEffect(() => {
    Tenant.instance().effects.loadChatWidget(setBioCatchCsid, user, embedWidgetApp, chatRef);
  }, []);

  // logout
  useEffect(() => {
    if (Tenant.instance().session.isValid() && /\/login/.test(window.location.pathname)) {
      setIsAuthenticated(false);
      Tenant.instance().session.clear();
    }
  }, []);

  /*
    for IB only
    set bioCatch when bioCatchCsid is loaded and after logout()
  */
  useEffect(() => {
    if (bioCatchCsid && !isAuthenticated) {
      Tenant.instance().effects.setBioCatchContext(user, constants.ib.BIOCATCH_LOGIN_PAGE_CONTEXT);
    }
  }, [bioCatchCsid, user, isAuthenticated]);

  const viewport = useMemo(
    () => (
      <AppViewport>
        <MiniAppInstance
          appInstance={activeAppInstance}
          loadingSlot={() => null}
          errorSlot={() => <GenericError errorTitle={constants.common.LOGIN_ERROR_MESSAGE_TITLE} />}
        />
      </AppViewport>
    ),
    [activeAppInstance]
  );

  const identityHeadingTitle = mainRef?.current?.querySelector('h1')?.innerHTML;

  return (
    <>
      <GlobalHeader activeAppInstance={activeAppInstance} mainRef={mainRef} isAuthenticated={isAuthenticated} />
      {showNotificationBanner && (
        <NotificationBanner
          title={bannerTitle}
          body={bannerBody}
          identityLoaded={identityLoaded}
          identityHeadingTitle={identityHeadingTitle}
        />
      )}
      <StyledIdentityWrapper imageUrl={configMgr.get('LOGIN_BACKGROUND_IMAGE_URL')} ref={mainRef} tabIndex={-1}>
        <StyledLoginWrapper data-testid="login-wrapper" fullHeight={!iframeLoaded}>
          <StyledLogginInnerWrapper>
            <Container>
              <Row>
                <Col md="8" sm="10" xs="12">
                  <MenuAndViewport>{isUsingIdentityStub ? <IdentityStub /> : viewport}</MenuAndViewport>
                </Col>
              </Row>
            </Container>
          </StyledLogginInnerWrapper>
          <div id="nab-chat-widget" ref={chatRef} />
        </StyledLoginWrapper>
        {identityLoaded && enableLoginTipsIframe && <LoginTipsIframe onLoaded={() => setIframeLoaded(true)} />}
      </StyledIdentityWrapper>
    </>
  );
};
