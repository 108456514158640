import { SpaProps } from '@nab/x-spa-react';
import GlobalHeader from 'components/GlobalHeader/GlobalHeader';
import { Viewport } from 'components/common';
import React, { useEffect, useMemo, useRef } from 'react';
import { Container } from '@nab/nui-react';
import { Tenant } from 'tenants/_strategy';

export const CreateProfileSpa: React.FC<SpaProps> = ({ activeAppInstance }) => {
  const mainRef = useRef(null);

  // logout
  useEffect(() => {
    if (Tenant.instance().session.isValid() && /\/register/.test(window.location.pathname)) {
      Tenant.instance().session.clear();
    }
  }, []);

  const viewport = useMemo(
    () => (
      <Container>
        <Viewport activeAppInstance={activeAppInstance} />
      </Container>
    ),
    [activeAppInstance]
  );

  return (
    <>
      <GlobalHeader activeAppInstance={activeAppInstance} mainRef={mainRef} />
      <main ref={mainRef} tabIndex={-1} data-testid="create-profile-spa">
        {viewport}
      </main>
    </>
  );
};
