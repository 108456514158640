import { IHeaderConfig } from 'tenants/_strategy';
import { HeaderEventName, MenuItemType } from '@nab/nui-react-molecule';
import { LogOutIcon } from '@nab/nui-react';

export const headerConfig: IHeaderConfig = {
  headerTitle: {
    alt: 'Adviser Registration',
    title: 'Adviser Registration'
  },
  preLoginItems: [],
  postLoginItems: [
    {
      title: 'Logout',
      type: MenuItemType.BUTTON,
      eventName: HeaderEventName.LOGOUT,
      showBtnIconOnSmScreen: true,
      buttonIcon: LogOutIcon
    }
  ]
};
