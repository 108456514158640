import styled from 'styled-components';

export const MenuHeading = styled.h2`
  margin: 10px;
  color: ${props => props.theme.color.grey90};
`;

export const MenuSub = styled.div`
  padding: 0px 20px;
`;

export const MenuItem = styled.div`
  display: block;

  .menu-link {
    color: ${props => props.theme.color.grey70};
    text-decoration: none;
    padding: 10px 20px;
    display: inline-block;

    &:hover,
    &:focus,
    &:visited,
    &:active {
      border-left: 2px solid ${props => props.theme.color.primary};
    }
  }
`;
