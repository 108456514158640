import { HeaderEventName, MenuItemType } from '@nab/nui-react-molecule';
import { LogOutIcon } from '@nab/nui-react';
import { IHeaderConfig } from 'tenants/_strategy';
import { constants } from "../../constants";

export const headerConfig: IHeaderConfig = {
  headerTitle: {
    alt: 'NAB Portal Pay',
    title: 'NAB Portal Pay'
  },
  preLoginItems: [
    {
      title: 'Support',
      displayTitle: true,
      type: MenuItemType.ANCHOR,
      target: '_blank',
      url: constants.npp.SUPPORT_LINK,
      hasExternalIcon: true
    }
  ],
  postLoginItems: [
    {
      title: 'Support',
      displayTitle: true,
      type: MenuItemType.ANCHOR,
      target: '_blank',
      url: constants.npp.SUPPORT_LINK,
      hasExternalIcon: true,
      displayOnHamburgerMenu: true
    },
    {
      title: 'Logout',
      type: MenuItemType.BUTTON,
      eventName: HeaderEventName.LOGOUT,
      showBtnIconOnSmScreen: true,
      buttonIcon: LogOutIcon
    }
  ]
};
