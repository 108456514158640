import { Notification } from '@nab/nui-react';
import { estimateTextReadingTime } from 'helpers/estimateTextReadingTime';
import React, { useEffect, useRef } from 'react';

import { ContainerWrapper, NotificationBannerWrapper } from './NotificationBanner.style';

export interface NotificationBannerProps {
  title: string;
  body: string;
  identityLoaded?: boolean;
  identityHeadingTitle?: string;
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  title,
  body,
  identityLoaded,
  identityHeadingTitle
}) => {
  const bannerRef = useRef(null);

  /**
   * A11y Requirements.
   * This useEffect hook is responsible for setting a timeout to focus on the notification banner after the estimated reading time of the identity heading title.
   * This is done to ensure that the screen reader announces the identity heading title first (H1 element from identity-miniapp) when the user loads the page and the banner is ON,
   * and then announces the notification banner message after a delay.
   * The delay is calculated based on the estimated reading time of the identity heading title.
   */
  useEffect(() => {
    if (identityLoaded) {
      const estimatedReadTime = estimateTextReadingTime(identityHeadingTitle);

      const timer = setTimeout(() => bannerRef.current?.focus(), estimatedReadTime);

      return () => clearTimeout(timer);
    }
  }, [identityLoaded, identityHeadingTitle]);

  return (
    <NotificationBannerWrapper
      data-testid="notification-banner-wrapper"
      aria-live="polite"
      tabIndex={0}
      ref={bannerRef}
    >
      <ContainerWrapper>
        <Notification id="notification-banner" type="info" variant="local">
          <strong>{title}</strong> {body || ''}
        </Notification>
      </ContainerWrapper>
    </NotificationBannerWrapper>
  );
};

export default NotificationBanner;
