import { constants } from 'tenants/constants';
import { IUser } from '@nab/x-types';

import { TenantHelpers } from 'tenants/_strategy';

export class Helpers extends TenantHelpers {
  public override getErrorMessage(code: number | string): string {
    return constants.npp.ERROR_MESSAGE_MAP[code] ?? constants.npp.DEFAULT_LOGIN_ERROR_MESSAGE;
  }
}
