import React, { useState } from 'react';
import { Container, Row, Col } from '@nab/nui-react';
import type { IframeResizerProps } from 'iframe-resizer-react';

import IframeResizer from 'iframe-resizer-react';
import { configMgr } from 'lib/configMgr';

import { constants } from 'tenants/constants';

import { StyledIframeContainer, StyledIframeInnerWrapper } from './LoginTipsIframe.style';

type IframeResizeData = Parameters<IframeResizerProps['onResized']>[0];

const LoginTipsIframe: React.FC<{ onLoaded?: () => void }> = ({ onLoaded }) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const setIframeParameters = async (data: IframeResizeData) => {
    if (+data.height > constants.ib.LOGIN_TIPS_MIN_HEIGHT) {
      setIsEmpty(false);
      onLoaded?.();
    }
  };

  return (
    <StyledIframeContainer isEmpty={isEmpty}>
      <Container>
        <Row>
          <Col>
            <StyledIframeInnerWrapper>
              <IframeResizer
                title={constants.ib.LANGUAGE.labels.LOGIN_TIPS}
                heightCalculationMethod="lowestElement"
                src={configMgr.get('LOGIN_TIPS_IFRAME_URL')}
                style={{ border: 'none' }}
                width="100%"
                height="100%"
                onResized={setIframeParameters}
              />
            </StyledIframeInnerWrapper>
          </Col>
        </Row>
      </Container>
    </StyledIframeContainer>
  );
};

export default LoginTipsIframe;
