import { registerSpaComponent, start } from '@nab/x-spa-react';

import { AdviserRegistrationSpa } from 'components/AdviserRegistrationSpa/AdviserRegistrationSpa';
import { LinkageSpa } from 'components/LinkageSpa/LinkageSpa';
import { LoginSpa } from 'components/LoginSpa/LoginSpa';
import { CreateProfileSpa } from 'components/NabPortalPaySpa/CreateProfileSpa';
import { NabPortalPaySpa } from 'components/NabPortalPaySpa/NabPortalPaySpa';
import { configMgr } from 'lib/configMgr';
import { Tenant } from 'tenants/_strategy';
import { ArStrategy } from 'tenants/adviser-registration';
import { constants } from 'tenants/constants';
import { IbStrategy } from 'tenants/internet-banking';
import { NppStrategy } from 'tenants/nab-portal-pay';
import { MINIAPP_SPA } from 'tenants/types';

registerSpaComponent(MINIAPP_SPA.LOGIN, LoginSpa);
registerSpaComponent(MINIAPP_SPA.LINKAGE, LinkageSpa);
registerSpaComponent(MINIAPP_SPA.PORTAL_PAY, NabPortalPaySpa);
registerSpaComponent(MINIAPP_SPA.ADVISER_REGISTRATION, AdviserRegistrationSpa);
registerSpaComponent(MINIAPP_SPA.CREATE_PROFILE, CreateProfileSpa);

window.addEventListener('DOMContentLoaded', async () => {
  try {
    await configMgr.init();

    const tenant = configMgr.get('TENANCY_NAME').toLowerCase();

    switch (tenant) {
      case constants.common.NAB_PORTAL_PAY_TENANT: {
        Tenant.instance(new NppStrategy());
        break;
      }
      case constants.common.IB_TENANT: {
        Tenant.instance(new IbStrategy());
        break;
      }
      case constants.common.ADVISER_REGISTRATION_TENANT: {
        Tenant.instance(new ArStrategy());
        break;
      }
      default: {
        Tenant.instance(new IbStrategy());
        console.error('Tenant is not properly defined!');
        break;
      }
    }

    const initConfig = await Tenant.instance().core.rootInitConfig();

    await start(initConfig);
  } catch (e) {
    console.error('SPA failed to start', e); // eslint-disable-line no-console
    const child = document.createElement('h1');
    child.innerText = 'Page not available';
    document.getElementById('appRoot').appendChild(child);
  }
});
