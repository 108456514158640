import styled from 'styled-components';

export const AppViewport = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex: 1;

  > div {
    width: 100%;
    height: 100%;
  }

  > .hidden {
    display: none;
  }

  @keyframes rotateLoader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  miniapp-iframe div[data-component-id='Spinner'] {
    div {
      > div:first-child {
        display: none;
      }
      > div:nth-child(2) {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        border-top: 0.3125rem solid rgba(0, 0, 0, 0.1);
        border-right: 0.3125rem solid rgba(0, 0, 0, 0.1);
        border-bottom: 0.3125rem solid #c20000;
        border-left: 0.3125rem solid #c20000;
        animation: rotateLoader 1.56s infinite linear;
        margin-bottom: 1rem;
      }
    }
  }

  iframe#ClassicIFrame {
    @media screen and (min-width: 1328px) {
      padding: 0 1.5rem;
    }
    min-height: calc(100svh - 175px);
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    overflow-y: clip;
  }
`;
