import { Container } from '@nab/nui-react';
import styled from 'styled-components';

export const NotificationBannerWrapper = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.white};
`;

export const ContainerWrapper = styled(Container)`
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
`;
