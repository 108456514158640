type ThemeValue = (theme: {
  theme: {
    color: {
      grey50: string;
      white: string;
      grey30: string;
      grey96: string;
      grey10: string;
      black: string;
      grey90: string;
      primary: string;
    };
    spaces: { xlg: string; xxlg: string; md: string; sm: string; xs: string; lg: string };
    font: { weight: { semibold: number } };
    zIndex: { fixed: number; dropdown: number };
  };
}) => string | number;
// Spaces

/**
 * 0.5 rem
 */
export const SPACE_XS: ThemeValue = ({ theme }) => theme.spaces.xs;
/**
 * 1 rem
 */
export const SPACE_SM: ThemeValue = ({ theme }) => theme.spaces.sm;
/**
 * 1.5 rem
 */
export const SPACE_MD: ThemeValue = ({ theme }) => theme.spaces.md;
/**
 * 2 rem
 */
export const SPACE_LG: ThemeValue = ({ theme }) => theme.spaces.lg;
/**
 * 2.5 rem
 */
export const SPACE_XLG: ThemeValue = ({ theme }) => theme.spaces.xlg;
/**
 * 3 rem
 */
export const SPACE_XXLG: ThemeValue = ({ theme }) => theme.spaces.xxlg;

// Colors
export const GREY_10: ThemeValue = ({ theme }) => theme.color.grey10;
export const GREY_30: ThemeValue = ({ theme }) => theme.color.grey30;
export const GREY_50: ThemeValue = ({ theme }) => theme.color.grey50;
export const GREY_90: ThemeValue = ({ theme }) => theme.color.grey90;
export const GREY_96: ThemeValue = ({ theme }) => theme.color.grey96;
export const BLACK: ThemeValue = ({ theme }) => theme.color.black;
export const WHITE: ThemeValue = ({ theme }) => theme.color.white;
export const PRIMARY: ThemeValue = ({ theme }) => theme.color.primary;

// Fonts

export const SEMIBOLD: ThemeValue = ({ theme }) => theme.font.weight.semibold;

// Z-Index

export const ZINDEX_FIXED: ThemeValue = ({ theme }) => theme.zIndex.fixed;
export const ZINDEX_DROPDOWN = Object.assign(({ theme }) => theme.zIndex.dropdown, {
  minus:
    (value: number): ThemeValue =>
    ({ theme }) =>
      Number(theme.zIndex.dropdown) - value
}) as ThemeValue & { minus: (value: number) => ThemeValue };
