import React from 'react';
import EventMonitor from 'components/DevSidePanel/EventMonitor/EventMonitor';
import EventDispatcher from 'components/DevSidePanel/EventMonitor/EventDispatcher';

import { MenuHeading } from './SidePanel.style';
import Accordion from './Accordion';

export interface SidePanelProps {}
const SidePanel: React.FC<SidePanelProps> = () => {
  return (
    <>
      <MenuHeading>Developer tools</MenuHeading>
      <Accordion title="Event Monitor">
        <EventMonitor />
      </Accordion>
      <Accordion title="Event Dispatcher">
        <EventDispatcher />
      </Accordion>
    </>
  );
};

export default SidePanel;
